
.loadingAnimationDiv {
    position: absolute;
    background-color: white;
    width: 98vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadingAnimation {
    height: 80vh;
    transform: translateY(-75px);
}

.demoVideo {

    height: 60vh;
    position: fixed;
    border-radius: 7.5px;

}

.hidden {
    display: none;
}

.LoadingText {
    font-size: 2rem;
    margin-top: -10rem;
    color: #160042;
    text-align: center;
    word-spacing: 5px;
    font-family: 'Outfit';
    font-size: 32px;
    font-weight: 400;
}


@media only screen and (max-width: 768px) {



    .loadingAnimation {
        transform: translateY(-75px);
    }

    .LoadingText {
        margin-top: -14rem;
        color: #160042;
        text-align: center;
        word-spacing: 5px;
        font-family: 'Outfit';
        font-size: 20px;
        font-weight: 500;
    }

}