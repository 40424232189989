.mainContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    flex-direction: column;
}

.para {
    width: 50%;
    text-align: justify;
}



.editContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

}

.promptDiv {
    display: flex;
    flex-direction: column;
}

.buttonsDiv {
    padding: 2rem;
}



/*Loader*/

.hidden {
    display: none;
}


.loadingAnimationDiv {
    position: absolute;
    background-color: white;
    width: 98vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadingAnimation {
    height: 80vh;
    transform: translateY(-75px);
}

.LoadingText {
    font-size: 2rem;
    margin-top: -10rem;
    color: #160042;
    text-align: center;
    word-spacing: 5px;
    font-family: 'Outfit';
    font-size: 32px;
    font-weight: 400;
}


.background {
    margin: 10px;
    width: 150px;
}

.selected {
    border: 4px solid #6610f2;
}

.finalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.subtitles {
    display: flex;
    margin: auto;
    flex-wrap: wrap;
}

.subtitles video {
    margin: 5px;
    height: 20vh;
}

.audiosContainer {
    width: 70%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;

}

.audioItem {
    margin: 5px;
}

.backgrounds {
    display: flex;
}

.beta_logo {
    width: 2.3rem;
    display: inline-block;
    padding-right: 5px;

}

.videoDiv {
    display: flex;
}


.subtitlesContainer {
    width: 70%;
    margin: auto;
}

@media only screen and (max-width: 768px) {

    .editContainer {
        flex-direction: column;

    }

    .para {
        width: 80vw;
        text-align: justify;
    }

    .buttonsDiv {
        padding: 0;
        padding-top: 1rem;
    }

    .editOptions {
        width: 90%;
    }

    .editOption {
        width: min-content;
    }

    .loadingAnimation {
        transform: translateY(-75px);
    }

    .LoadingText {
        margin-top: -14rem;
        color: #160042;
        text-align: center;
        word-spacing: 5px;
        font-family: 'Outfit';
        font-size: 20px;
        font-weight: 500;
    }

}