@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 0px;
    }
    .scrollbar-style-8::-webkit-scrollbar-track{
        background-color: #F5F5F5;
        border-radius: 1rem;
    }
    .scrollbar-style-8::-webkit-scrollbar{
        width: 0.5rem;
        background-color: #F5F5F5;
        border-radius: 1rem;
    }
    .scrollbar-style-8::-webkit-scrollbar-thumb{
        border-radius: 1rem;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(208, 223, 255)), color-stop(0.72, rgb(152, 199, 255)), color-stop(0.86, rgb(102, 140, 255)));
    }
    /* Add custom utilities for non-grabbable image */
    .user-drag-none {
        -webkit-user-drag: none;
    }
    .user-select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .noise-background {
        @apply absolute inset-0 w-full h-full transform scale-[1.2] opacity-10;
        mask-image: radial-gradient(#fff, transparent, 75%);
        background-image: url('https://assets.aceternity.com/noise.webp');
        background-size: 30%;
    }
    .bright-light-top {
        @apply absolute inset-0;
        background-image: radial-gradient(88% 100% at top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    }
}