.chunk{
    border: solid 5px black;
    margin: 10px;
    display: flex;
    width: auto;
}
.layer{
    border:  solid red 3px;
    margin: 3px;
}
.asset{
    display: inline-block;
    margin: 3px;
    height: 300px;
}
.selected_asset{
    border: 4px solid blueviolet;
    margin: 3px;
    height: 150px;
    display: inline-block;

}
