.videoDiv{
  display: flex;
}

.background{
  margin: 10px;
  width: 150px;
}

.selected{
  border: 4px solid #6610f2;
}

.finalContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subtitles{
  display: flex;
}
.subtitles video{
  margin: 5px;
 height: 20vh;
}

.audiosContainer{
  display: flex;
}
.audioItem{
  margin: 5px;
}

.backgrounds{
  display: flex;
}

