.sliderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.sliderWrapper {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.slider {
  width: 100%;
  -webkit-appearance: none;
  background: #b29ddf; /* Light grey background for the track */
  height: 8px; /* Thickness of the slider track */
  border-radius: 5px; /* Rounded corners for the track */
  outline: none; /* Remove the default outline */
  transition: background 0.3s; /* Smooth transition for background change */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #4CAF50; /* Color of the slider thumb */
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s; /* Smooth transition for thumb color change */
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4CAF50; /* Color of the slider thumb */
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s; /* Smooth transition for thumb color change */
}

.slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #4CAF50; /* Color of the slider thumb */
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.3s; /* Smooth transition for thumb color change */
}

.sliderLabels {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #333;
  margin-top: 10px;
}

.sliderValue {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}
