.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-direction: column;
}

.para {
  width: 50%;
  text-align: justify;
}

.editContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.editOptions {
  width: 70%;
}

.buttonsDiv {
  padding: 2rem;
}

/*Loader*/

.hidden {
  display: none;
}


.loadingAnimationDiv {
  position: absolute;
  background-color: white;
  width: 98vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingAnimation {
  height: 80vh;
  transform: translateY(-75px);
}

.LoadingText {
  font-size: 2rem;
  margin-top: -10rem;
  color: #160042;
  text-align: center;
  word-spacing: 5px;
  font-family: 'Outfit';
  font-size: 32px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {

  .para {
    min-width: 50%;
    text-align: justify;
  }

  .buttonsDiv {
    padding-top: 2rem;
  }

  .editOptions {
    width: 90%;
  }

  .editOption {
    width: min-content;
  }

  .loadingAnimation {
    transform: translateY(-75px);
  }

  .LoadingText {
    margin-top: -14rem;
    color: #160042;
    text-align: center;
    word-spacing: 5px;
    font-family: 'Outfit';
    font-size: 20px;
    font-weight: 500;
  }

}