.videoDiv{
  display: flex;
}
.container{
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60vw;
  
}

.background{
  margin: 10px;
  width: 150px;
}

.selected{
  border: 4px solid #6610f2;
}

.finalContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subtitles{
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
}
.subtitles video{
  margin: 5px;
 height: 20vh;
}
.audiosContainer{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem; 
}
.audioItem{
  margin: 5px;
}

.backgrounds{
  display: flex;
}

.beta_logo{
  width: 2.3rem;
  display: inline-block;
  padding-right: 5px;
  
}

@media only screen and (max-width: 768px) {
  .container{
    width: 98vw;
  }
  .audiosContainer{
    flex-wrap: wrap;
    flex-direction: column;
  }
  .videoDiv{
    flex-wrap: wrap; 
    flex-direction: column;
  }
}
  