@import url("https://fonts.googleapis.com/css2?family=Playwrite+ES+Deco:wght@100..400&display=swap");

:root {
    --dot-distance: 50px;
    --dot-radius: 10%;
    --accent-color: 250, 128, 114;
}

.scrollDotContainer {
    margin: 0;
    padding: 0;
    height: 100vh; /* Full height for the container */
    width: 100vw; /* Full width for the container */
    overflow: hidden;
    background-color: black; /* Background color for the container */
}

.patternContainer {
    position: relative; /* reference point for absolute positioning */
    width: 100%; /* Use full width of the container */
    height: 100%; /* Use full height of the container */
}

.pattern {
    width: 200%;
    height: 100%;
    background-image: radial-gradient(
        circle,
        rgba(var(--accent-color), 0.6) var(--dot-radius),
        transparent var(--dot-radius)
    );
    background-size: var(--dot-distance) var(--dot-distance);
    background-position: calc(var(--dot-distance) / 2) 0; /* center the first vertical row of dots */
}

.centered {
    position: absolute;
    top: calc(50% - 3rem);
    left: 50%;
    transform: translate(-50%, -50%);
}

.scrollDotContainer p {
    font-family: 'PlayWrite ES Deco', sans-serif;
    font-size: 3rem;
    text-align: center;
    color: rgba(var(--accent-color), 1);
}