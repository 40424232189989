.feature_bullets_home{
    list-style-type:circle;
    color: red;
}

.home_features_li{
    list-style:DISC;
    text-align: left;
    margin-left: 0.8rem;
    font-size:17.5px;
}


/* .card_btn{
    margin-top: 2rem;
    height:10px;
    width:10px;
    /* background: red;
    padding: 0px 0px;
} 
*/
.call_tools{
    /* width: 100%; */
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; 
}

.mainContainer{
    padding: 5vw;
}

@media only screen and (max-width: 600px) {
    .feature_paragraph{
        display: none;
    }
    .home_cards_col2{
        flex-direction: column;
    }
    .card_btn{
        width: 100vw;
        text-align: right;
    }
    .home_features_li{
        font-size:14.5px;
    }
}
