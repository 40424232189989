.mainContainer {
    /* margin: 2rem; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    min-height: 70vh;
    max-width: fit-content;
}

.videoContainer {
    max-width: 35vw;
    background-color: #f3f4f6;
    /* padding-left: 5vw;
    padding-top: 2rem;
    padding-right: 5vw; */
}

.loadingAnimationDiv {
    /* position: absolute; */
    background-color: white;
    width: 98vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadingAnimation {
    height: 80vh;
    transform: translateY(-75px);
}

.demoVideo {
    top: 2rem;
    position: sticky;
    padding: 2rem;
}

.demoVideoPreview{
    width: 15rem;
    border-radius: 3px;
    transition: opacity 0.2s ease-in-out;
}

.hidden {
    display: none;
}

.LoadingText {
    font-size: 2rem;
    margin-top: -10rem;
    color: #160042;
    text-align: center;
    word-spacing: 5px;
    font-family: 'Outfit';
    font-size: 32px;
    font-weight: 400;
}
.videoDiv{
  display: flex;
}

/* Form Component */
.container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60vw;
}

.background{
  margin: 10px;
  width: 150px;
}

.selected{
  border: 4px solid #6610f2;
}

.finalContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subtitles{
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
}
.subtitles video{
  margin: 5px;
  height: 20vh;
}
.audiosContainer{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem; 
}
.audioItem{
  margin: 5px;
}

.backgrounds{
  display: flex;
}

.beta_logo{
  width: 2.3rem;
  display: inline-block;
  padding-right: 5px;
}

@media only screen and (max-width: 768px) {
    .mainContainer {
        flex-direction: column-reverse;
        gap: 0rem;
        margin: auto;
    }

    .videoContainer {
        all: unset;
        margin: auto;
    }

    .demoVideo {    
        max-width: 90vw;
        margin: auto;
    }

    .loadingAnimation {
        transform: translateY(-75px);
    }

    .LoadingText {
        margin-top: -14rem;
        color: #160042;
        text-align: center;
        word-spacing: 5px;
        font-family: 'Outfit';
        font-size: 20px;
        font-weight: 500;
    }

    /* Form Component */
    .container{
        width: 98vw;
    }
    .audiosContainer{
        flex-wrap: wrap;
        flex-direction: column;
    }
    .videoDiv{
        flex-wrap: wrap; 
        flex-direction: column;
    }
}