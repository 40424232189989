.videoDiv{
  display: flex;
}
.container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60vw;
}

.background{
  margin: 10px;
  width: 150px;
}

.selected{
  border: 4px solid #6610f2;
  border-radius: 0.5rem;
}

.finalContainer{
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subtitles{
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  align-items: center;
  justify-content: center;
}
.subtitles video{
  margin: 5px;
  height: 20vh;
}

.audiosContainer{
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem; 
  gap: 20px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.audioItem{
  margin: 5px;
}

.backgrounds{
  display: flex;
}

.beta_logo{
  width: 2.3rem;
  display: inline-block;
  padding-right: 5px;
  
}

.audioCard {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.audioCard:hover, .audioCard.selected {
  background-color: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.avatarInfo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.audioInfo {
  text-align: left;
}

.name {
  font-weight: bold;
  margin: 0;
}

.language {
  font-size: 0.8em;
  color: #666;
  margin: 0;
}

.playButton {
  background-color: #00a6ed;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .playButton:hover {
  background-color: #0056b3;
} */
  
@media only screen and (max-width: 768px) {
    .container{
        width: 98vw;
    }
    .audiosContainer{
        flex-wrap: wrap;
        flex-direction: column;
    }
    .videoDiv{
        flex-wrap: wrap; 
        flex-direction: column;
    }
}
    