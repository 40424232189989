.subtitles{
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
}

.subtitles video{
  margin: 5px;
  margin-top: 0px;
  height: 45vh;
}
.subtitles img{
  margin: 5px;
  margin-top: 0px;
  height: 70vh;
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.options {
  display: flex;
}


.descriptionText {
  flex: 1;
  /*margin-right: 20px;*/
  min-width: 300px;
}

.descriptionText h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;

}

.descriptionText p {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.descriptionBox {
  box-shadow: 4px 4px 4px 4px rgba(181, 188, 213, 0.25);
  border-radius: 20px;
  background: var(--tg-white);
  padding: 50px 20px 45px 50px;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  border: solid 1px var(--tg-black);
  max-width: 1200px;
  margin: 20px auto;
}

.descriptionContent {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.demoVideoContainer {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
}

.demoVideo {
  width: 476px;
  height: 268px;
  border-radius: 10px;
  max-width: 100%;
}

.demoImageContainer {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
}

.demoImage {
  width: auto;
  height: 100%;
  max-height: 500px;
  border-radius: 10px;
}

.uploadedVideo {
  height: 9rem;
  padding-bottom: 0.5rem;
  border-radius: 2rem;
  /* width: 6rem; */
}

@media (max-width: 768px) {

  .options {
    flex-direction: column;
  }

  .descriptionContent {
    flex-direction: column;
    align-items: center;
  }

  .descriptionText,
  .demoVideoContainer,
  .demoImageContainer {
    min-width: 100%;
  }

  .demoVideo,
  .demoImage {
    width: 100%;
    height: auto;
  }
}

.subtitleContainer {
  margin: auto;
  /* width: 50vw; */
}

.selected {
  border: 4px solid #6610f2;
}

.hidden {
  display: none;
}


.animation {
  width: 98vw;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loadingAnimation {
  height: 50%;
}

/*.demoVideo {

  flex-shrink: 0;
  width:"320px";
  height:"440px";
  border-radius: 7.5px;

}
*/
.hidden {
  display: none;
}

.LoadingText {
  max-width: 50vw;
  position: absolute;
  font-size: 2rem;
  margin-top: 29rem;
  color: #160042;
  text-align: center;
  word-spacing: 5px;
  font-family: 'Outfit';
  font-size: 32px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {

  .subtitleContainer {
    width: unset;
  }


  .loadingAnimation {
    transform: translateY(-75px);
  }

  .LoadingText {
    margin-top: -14rem;
    color: #160042;
    text-align: center;
    word-spacing: 5px;
    font-family: 'Outfit';
    font-size: 20px;
    font-weight: 500;
  }

}