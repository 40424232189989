.container{
    width: 98vw;    
    /* padding-top: 2rem; */
    /* padding-bottom: 2rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tv{
    /* width: 30vw;
    background-color: #ffcacc; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.fe{
    /* width: 70vw;
    background-color: #dbc4f0; */
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 540px){
    .container{
        flex-direction: column;
    }
    .tv{
        width: 100vw;
    }
    .fe{
        width: 100vw;
    }
}