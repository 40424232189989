.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}

.finalvideo {
  border-radius: 0.5rem;
  height: 70vh;
}

.copyLinkButton {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  /* Can be removed */
  /* display: flex;
  flex-direction: row-reverse; */
}

/* From Uiverse.io by Praashoo7 */ 
.main {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em;
  justify-content: center;
  align-items: center;
  /* border-width: 1px;
  border-radius: 0.75rem;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity)); */
}

.up {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.down {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.card1 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 90px 5px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.instagram {
  margin-top: 1.5em;
  margin-left: 2.4em;
  fill: #cc39a4;
}

.card2 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 90px 5px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.twitter {
  margin-top: 1.5em;
  margin-left: 1.4em;
  fill: #03A9F4;
}

.card3 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 5px 5px 90px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.github {
  margin-top: -.6em;
  margin-left: 2.4em;
}

.card4 {
  width: 90px;
  height: 90px;
  outline: none;
  border: none;
  background: white;
  border-radius: 5px 5px 90px 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: .2s ease-in-out;
}

.discord {
  margin-top: -.9em;
  margin-left: 1.6em;
  fill: #8c9eff;
}

.card1:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #cc39a4;
}

.card1:hover .instagram {
  fill: white;
}

.card2:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #03A9F4;
}

.card2:hover .twitter {
  fill: white;
}

.card3:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: black;
}

.card3:hover .github {
  fill: white;
}

.card4:hover {
  cursor: pointer;
  scale: 1.1;
  background-color: #8c9eff;
}

.card4:hover .discord {
  fill: white;
}

.feedbackLink {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-width: 1px;
  border-radius: 0.75rem /* 12px */;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity)) /* #e2e8f0 */;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) /* #ffffff */;

  /* Can be removed */
  /* display: flex;
  flex-direction: column;
  gap: 1rem; */
}

@media only screen and (max-width: 1680px){
  .copyLinkButton {
    display: flex;
    flex-direction: row-reverse;
  }
  .feedbackLink{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
@media only screen and (max-width: 540px){
  .mainContainer {
    flex-direction: column;
  }
  .copyLinkButton{
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .finalvideo{
    border-radius: 1rem;
  }
}