@import url('https://fonts.googleapis.com/css?family=Abel');

.pageHeading {
    --tw-text-opacity: 1;
    color: var(--tg-black);
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
}

.card {
    /* width: 450px; */
    height: 250px;
    background-color: #fff;
    background: linear-gradient(#f8f8f8, #fff);
    box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.card .additional {
    position: absolute;
    width: 150px;
    height: 100%;
    background: linear-gradient(#dE685E, #EE786E);
    transition: width 0.4s;
    overflow: hidden;
    z-index: 2;
}

.card.green .additional {
    background: linear-gradient(#92bCa6, #A2CCB6);
}


.card:hover .additional {
    width: 100%;
    border-radius: 0 5px 5px 0;
}

.card .additional .userCard {
    width: 150px;
    height: 100%;
    position: relative;
    float: left;
}

.card .additional .userCard::after {
    content: "";
    display: block;
    position: absolute;
    top: 10%;
    right: -2px;
    height: 80%;
    border-left: 2px solid rgba(0, 0, 0, 0.025);
}

.card .additional .userCard .level,
.card .additional .userCard .points {
    top: 15%;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.15);
    padding: 0.125rem 0.75rem;
    border-radius: 100px;
    white-space: nowrap;
}

.card .additional .userCard .points {
    top: 85%;
}

.card .additional .userCard svg {
    top: 50%;
}

.card .additional .moreInfo {
    width: 300px;
    float: left;
    position: absolute;
    left: 150px;
    height: 100%;
}

.card .additional .moreInfo {
    color: #fff;
    margin-bottom: 0;
}

.card.green .additional .moreInfo {
    color: #224C36;
}

.card .additional .coords {
    margin: 0 1rem;
    color: #fff;
    font-size: 1rem;
}

.card.green .additional .coords {
    color: #325C46;
}

.card .additional .coords span+span {
    float: right;
}

.card .additional .stats {
    font-size: 2rem;
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    top: auto;
    color: #fff;
}

.card.green .additional .stats {
    color: #325C46;
}

.card .additional{
    flex: 1;
    text-align: center;
}

.card .additional .stats i {
    display: block;
}

.card .additional .state {
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
}

.card .additional .stats div.value {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5rem;
}

.card .additional .stats div.value.infinity {
    font-size: 2.5rem;
}

.card .general {
    width: 300px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 0;
}

.card .general .more {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 0.9em;
}