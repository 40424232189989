
.descriptionText {
  flex: 1; 
  /*margin-right: 20px;*/
  min-width: 300px;
}

.descriptionText h2 {
  font-size: 1.5rem; 
  margin-bottom: 1rem; 
  
}

.descriptionText p {
  font-size: 1rem; 
  margin-bottom: 0.25rem; 
}

.descriptionBox {
  box-shadow: 4px 4px 4px 4px rgba(181, 188, 213, 0.25);
  border-radius: 20px;
  background: var(--tg-white);
  padding: 50px 20px 45px 50px;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  border: solid 1px var(--tg-black);
  max-width: 1200px; 
  margin: 20px auto;
  
}
.descriptionContent {
  display: flex;
  align-items: flex-start; 
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.demoVideoContainer {
  flex: 1;
  min-width: 300px; 
  display: flex;
  justify-content: center; 
}

.demoVideo {
  width: 476px; 
  height: 268px; 
  border-radius: 10px;
  max-width: 100%; 
}

@media (max-width: 768px) {
  .descriptionContent {
    flex-direction: column; 
    align-items: center;
  }

  .descriptionText, .demoVideoContainer {
    min-width: 100%;
  }
  .demoVideo {
    width: 100%; 
    height: auto; 
  }
}
.mainContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.hidden{
    display: none;
  }

  
.loadingAnimationDiv {
  position: absolute;
  background-color: white;
  width: 98vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingAnimation {
  height: 80vh;
  transform: translateY(-75px);
}

/*.demoVideo {

  height: 60vh;
  position: fixed;
  border-radius: 7.5px;

}
*/
.hidden {
  display: none;
}

.LoadingText {
  font-size: 2rem;
  margin-top: -10rem;
  color: #160042;
  text-align: center;
  word-spacing: 5px;
  font-family: 'Outfit';
  font-size: 32px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {



  .loadingAnimation {
      transform: translateY(-75px);
  }

  .LoadingText {
      margin-top: -14rem;
      max-width: 60vw;
      color: #160042;
      text-align: center;
      word-spacing: 5px;
      font-family: 'Outfit';
      font-size: 20px;
      font-weight: 500;
  }

}