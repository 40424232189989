.videoDiv {
  display: flex;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70vw;
}

.background {
  margin: 10px;
  width: 150px;
}

.selected {
  border: 4px solid #6610f2;
  border-radius: 0.5rem;
}

.finalContainer {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subtitles{
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  align-items: center;
  justify-content: center;
}
.subtitles video{
  margin: 5px;
  height: 20vh;
}

.audiosContainer {
  display: flex;
}

.audioItem {
  margin: 5px;
}

.backgrounds {
  display: flex;
}

.subtypes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadedVideo {
  height: 9rem;
  padding-bottom: 0.5rem;
  border-radius: 2rem;
  /* width: 6rem; */
}

@media only screen and (max-width: 768px) {

  .videoDiv {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .buttons {
    width: max-content;
    margin: auto;
  }

  .backgrounds {
    display: flex;
    width: 90vw;
  }

  .background {
    width: 90vw;
  }

  .selected {
    border: 4px solid #6610f2;
  }

  .subtitles{
    display: flex;
    flex-wrap: wrap;
    margin: 1rem;
    align-items: center;
    justify-content: center;
  }

  .subtitles video{
    margin: 5px;
    height: 12vh;
    border-radius: 4px;
  }

}